import React from "react";
import { I18n } from "i18n-js";

import translations from "./../../locales.json";

const Design = (props) => {
  const i18n = new I18n(translations);
  i18n.locale = "en";
  return <>Hello</>;
};
export default Design;
